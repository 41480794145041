export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '16eb4e14-e1a4-4ef7-b340-072efbba1c05',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '4de969a4-083d-48aa-aa22-27478ee8b28b',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://nextocean.services4eo.com/'
};
